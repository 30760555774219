import React from 'react';
import { FiRefreshCw } from 'react-icons/fi';
import { Container, Heading, Stack } from '@chakra-ui/react';

import { EngagementDto } from '../../api/dto/engagement.dto';
import { CustomBreadcrumb } from '../../components/CustomBreadcrumb';
import GenericButton from '../../components/GenericButton';
import Loading from '../../components/Loading';
import { useTenant } from '../../hooks/useTenants';
import { ROOT, TENANTED_ENGAGEMENTS_PAGE } from '../../utils/internal-routes';

import { EngagementStatusCard } from './components/engagementStatusCard';
import UpdateAdBoardEngagementForm from './components/UpdateAdBoardEngagementForm';

type AdBoardEngagementViewPropTypes = {
  tenantName: string;
  engagement: EngagementDto;
  fetchData: () => void;
  isLoading: boolean;
  handleRefreshAdBoard: () => void;
  handleDownloadAdBoardResultsExport: () => void;
};

const AdBoardEngagementView = ({
  tenantName,
  engagement,
  fetchData,
  isLoading,
  handleRefreshAdBoard,
  handleDownloadAdBoardResultsExport,
}: AdBoardEngagementViewPropTypes) => {
  const tenant = useTenant();
  if (!tenant || !engagement || isLoading) {
    return <Loading />;
  }
  return (
    <Container maxWidth="1600px" width="100%" margin="auto">
      <CustomBreadcrumb
        list={[
          { text: 'Home', link: ROOT },
          {
            text: `${tenantName} Engagements`,
            link: TENANTED_ENGAGEMENTS_PAGE.replace(':tenantId', tenant.id),
          },
          ...(engagement ? [{ text: engagement.name, link: '' }] : []),
        ]}
      />
      <Stack
        spacing={3}
        justifyContent="space-between"
        alignItems="center"
        direction="row"
      >
        <Heading
          as="h1"
          fontSize={24}
          lineHeight={'32px'}
          textAlign="left"
          margin={5}
        >
          {engagement?.name}
        </Heading>

        <GenericButton
          type="button"
          text="Refresh AdBoard"
          background="white"
          leftIcon={<FiRefreshCw aria-label="preview-icon" />}
          margin={0}
          isLoading={isLoading}
          onClick={handleRefreshAdBoard}
        />

        <GenericButton
          type="button"
          text="Download Results Export"
          background="white"
          leftIcon={<FiRefreshCw aria-label="preview-icon" />}
          margin={0}
          isLoading={isLoading}
          onClick={handleDownloadAdBoardResultsExport}
        />
      </Stack>
      <Stack
        spacing={3}
        marginTop={8}
        marginRight={0}
        padding={3}
        justifyContent="space-between"
        direction="row"
      >
        <UpdateAdBoardEngagementForm
          engagement={engagement}
          isDisabled={isLoading}
        />
        <EngagementStatusCard
          engagement={engagement}
          fetchEngagement={fetchData}
          isDisabled={isLoading}
        />
      </Stack>
    </Container>
  );
};

export default AdBoardEngagementView;
